import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { Alert } from "../../interfaces/alert.interface";

export const AlertActions = createActionGroup({
  source: 'Alert',
  events: {
    Get: props<{ id: string }>(),
    Create:props<{ alert: Alert }>(),
    Delete: props<{ id: string }>(),
    'Clear state': emptyProps(),
  },
});
